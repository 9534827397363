import {Box, Container, Paper, Typography} from '@mui/material'
import React from 'react'

export const ImpressView = () => {
  return (
    <>
      <Container sx={{maxWidth: 850}}>
        <Box maxWidth={280}>
          <Paper elevation={1} sx={{p: 2, justifyContent: 'center'}}>
            <Typography fontSize={15} fontWeight="bold">Alfons Hoferer</Typography>
            <Typography fontSize={15}>Schönberg 4</Typography>
            <Typography fontSize={15}>84564 Oberbergkirchen</Typography>
            <Typography fontSize={14} sx={{mt: 2}}>E-Mail: info at pray-easy.de</Typography>
          </Paper>
        </Box>
        <Box maxWidth={280} marginTop={3}>
          <Paper elevation={1} sx={{p: 2, justifyContent: 'center'}}>
            <Typography fontSize={14} fontStyle="italic">Gewidmet:</Typography>
            <Typography fontSize={15} fontStyle="italic">meinem Patenkind Jonas</Typography>
            <Typography sx={{mt: 2}} fontSize={14}>-</Typography>
            <Typography sx={{mt: 2}} fontSize={14} fontStyle="italic">
              Im Gedenken an den verstorbenen
            </Typography>
            <Typography fontSize={14} fontStyle="italic">
              Thomas Rohrbacher,
            </Typography>
            <Typography fontSize={14} fontStyle="italic">
              einem Unterstützer von Pray easy
            </Typography>
          </Paper>
        </Box>
        <Typography fontSize={14} marginTop={3}>
          Meine Homepage ist eine rein private und nicht kommerzielle Homepage.
        </Typography>
      </Container>
    </>
  )
}
